import React, { useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { Box, FormControl, FormControlLabel } from '@mui/material'
import Radio from 'antd/es/radio'
import { FLAG_FEATURES } from 'api/src/common/enums'
import {
  AncillaryTableQuery,
  AncillaryTableQueryVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import AncilTable from 'src/components/Ancil/AncilTable'
import { ANCIL_TABLE_QUERY } from 'src/components/Ancil/gql/queries'
import TabNavigation, { TabsEnum } from 'src/components/Ancil/TabNavigation'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import Switch from 'src/components/Library/Switch'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

import PageHeader from '../../components/PageHeader/PageHeader'

export type AncillaryLead = AncillaryTableQuery['ancillaryLeads'][0]

const AncilPage = () => {
  const [showHasConsentOnly, setShowHasConsentOnly] = useState<boolean | null>(
    null,
  )
  const [showActiveOnly, setShowActiveOnly] = useState(true)
  const [query, setQuery] = useState<string>('')
  const queryParams = new URLSearchParams(location.search)
  const currentTab = queryParams.get('tab') || TabsEnum.NAME

  const handleTabChange = (newTab: string) => {
    navigate(`${routes.ancil()}?tab=${newTab}`)
  }

  const { data, loading, error } = useQuery<
    AncillaryTableQuery,
    AncillaryTableQueryVariables
  >(ANCIL_TABLE_QUERY, {
    variables: {
      ...(showHasConsentOnly !== null && { hasConsent: showHasConsentOnly }),
      onlyActive: showActiveOnly,
    },
  })

  return (
    <>
      <Metadata title="Ancillary Services" description="Ancillary Services" />
      <PageHeader
        title="Ancillary Services"
        parentDataTestId="audit-log-page"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.ANCIL}
        InactiveComponent={<FeatureDenied />}
      >
        <Box
          p={3}
          pb={2}
          className={'flex w-full items-center justify-between'}
        >
          <Box className={'flex'} gap={3}>
            <Box>
              <SearchField
                value={query}
                onChange={setQuery}
                className={'!w-[350px]'}
              />
            </Box>
            <Box className={'-mt-2'}>
              <TabNavigation tab={currentTab} onTabChange={handleTabChange} />
            </Box>
          </Box>
          <Box display={'flex'} gap={3} alignItems={'center'}>
            <Radio.Group
              value={showHasConsentOnly}
              buttonStyle="solid"
              onChange={(e) => setShowHasConsentOnly(e.target.value)}
            >
              <Radio.Button value={null}>All</Radio.Button>
              <Radio.Button value={true}>
                <DoneAllIcon className={'mr-2 h-4 w-4'} />
                {'Pre-Consented Only'}
              </Radio.Button>
              <Radio.Button value={false}>
                <CloseIcon className={'mr-2 h-4 w-4'} />
                {'Non-Consented Only'}
              </Radio.Button>
            </Radio.Group>
            <Box>
              <FormControl className={''}>
                <FormControlLabel
                  label={'Include Closed Leads'}
                  control={
                    <Switch
                      checked={!showActiveOnly}
                      color={'success'}
                      onChange={() => setShowActiveOnly(!showActiveOnly)}
                      className={'mr-1'}
                    />
                  }
                  data-intercom-target={'live-feed-toggle'}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box p={3} pt={0}>
          <AncilTable
            ancillaryLeads={data?.ancillaryLeads || []}
            loading={loading}
            error={error}
            activeTab={currentTab as TabsEnum}
            query={query}
          />
        </Box>
      </FeatureToggle>
    </>
  )
}

export default AncilPage
