import { FC } from 'react'

import type {
  FindSentimentAnalysisPermissionQuery,
  FindSentimentAnalysisPermissionQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import SentimentAnalysis from '../SentimentAnalysis'
import SentimentAnalysisDenied from '../SentimentAnalysisDenied'

export const beforeQuery = (props) => ({
  variables: props,
  nextFetchPolicy: 'network-only',
})

export const QUERY = gql`
  query FindSentimentAnalysisPermissionQuery {
    sentimentAnalysisPermission: sentimentAnalysisPermissionByMembershipId {
      id
      disabled
      invited
      lastSuccessfulRun
      lastManualJobCompleted
    }
    azureToken: azureTokenDetails(appName: EMAIL_SENTIMENT) {
      refreshTokenExpiresOn
    }
    overallESIScore: overallESIScore {
      overallScore
      landlordStatusCount {
        high
        mid
        low
      }
    }
  }
`

type Props = CellSuccessProps<
  FindSentimentAnalysisPermissionQuery,
  FindSentimentAnalysisPermissionQueryVariables
>
export type AzureToken = Props['azureToken']
export type OverallESIScore = Props['overallESIScore']
export type SentimentAnalysisPermission = Props['sentimentAnalysisPermission']
export type SentimentAnalysisRefetch = Props['queryResult']['refetch']
export const Empty: FC<Props> = ({ queryResult: { refetch } }) => (
  <SentimentAnalysis
    azureToken={null}
    overallESIScore={null}
    refetchPermissions={refetch}
    sentimentAnalysisPermission={null}
  />
)

export const Failure = ({
  error,
}: CellFailureProps<FindSentimentAnalysisPermissionQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<Props> = ({
  sentimentAnalysisPermission,
  azureToken,
  overallESIScore,
  queryResult: { refetch },
}) => {
  if (
    !sentimentAnalysisPermission ||
    !sentimentAnalysisPermission.invited ||
    sentimentAnalysisPermission.disabled
  ) {
    const getMessage = () => {
      if (!sentimentAnalysisPermission) {
        return 'You do not yet have permission to access Sentiment Analysis'
      }
      if (!sentimentAnalysisPermission.invited) {
        return 'You have not yet been invited to Sentiment Analysis'
      }
      if (sentimentAnalysisPermission.disabled) {
        return 'You do not have access to this feature.'
      }
    }
    const message = getMessage()

    return <SentimentAnalysisDenied message={message} />
  }

  return (
    <SentimentAnalysis
      sentimentAnalysisPermission={sentimentAnalysisPermission}
      azureToken={azureToken}
      overallESIScore={overallESIScore}
      refetchPermissions={refetch}
    />
  )
}
