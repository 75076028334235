import type {
  FindSettingsSentimentAnalysisScoreGridQuery,
  FindSettingsSentimentAnalysisScoreGridQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as EmptyGrid } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import {
  FilterByOption,
  SABucketOptions,
  SADateFilterOptions,
  SentimentAnalysisRatingOptions,
  type SentimentAnalysisFlagOptions,
  type SentimentStatusSelectOptions,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'

import SentimentAnalysisScoreGrid from '../SentimentAnalysisScoreGrid'

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const QUERY = gql`
  query FindSettingsSentimentAnalysisScoreGridQuery(
    $filterDateRange: SADateFilterOptions
  ) {
    memberships: membershipsByClient {
      id

      user {
        name
        email
        position
        avatarUrl
      }

      landlordsWithEmailsAndSentimentScores(filterDateRange: $filterDateRange) {
        id
        emailCensored
        csiRating
        # Relationship resolvers
        name
        totalEmailsCount
        unprocessedEmailsCount
        sentimentStatus
        emails {
          ...LandlordEmailFields
        }
      }
    }
  }
`

export const Loading = () => (
  <div className="flex min-h-[calc(100vh-14rem)] flex-col items-center justify-center">
    <LoadingSpinner />
  </div>
)

export const Empty = () => (
  <div
    className={
      'flex min-h-[calc(100vh-14rem)] flex-col items-center justify-center'
    }
  >
    <EmptyGrid title="No analysis results found" className="mt-10" />
  </div>
)

export const Failure = ({
  error,
}: CellFailureProps<FindSettingsSentimentAnalysisScoreGridQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export type FindSettingsSentimentAnalysisMembership =
  FindSettingsSentimentAnalysisScoreGridQuery['memberships'][number]

export const Success = ({
  memberships,
  sortByValues,
  filterByMember,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  debouncedSearchText,
}: CellSuccessProps<
  FindSettingsSentimentAnalysisScoreGridQuery,
  FindSettingsSentimentAnalysisScoreGridQueryVariables
> & {
  selectedBucket: SABucketOptions
  sortByValues: SelectionType
  filterByMember: FilterByOption[]
  filterDateRange: SADateFilterOptions
  filterESIRating: SentimentAnalysisRatingOptions
  filterByFlag: SentimentAnalysisFlagOptions
  filterByClientStatus: SentimentStatusSelectOptions
  debouncedSearchText: string
}) => {
  return (
    <SentimentAnalysisScoreGrid
      memberships={memberships}
      sortByValues={sortByValues}
      filterDateRange={filterDateRange}
      filterByMember={filterByMember}
      filterESIRating={filterESIRating}
      filterByFlag={filterByFlag}
      filterByClientStatus={filterByClientStatus}
      debouncedSearchText={debouncedSearchText}
    />
  )
}
